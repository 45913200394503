import { request } from '@/service/Axios';

const api = {
  list: '/api/v1/course/list',
  subscriptions: `/api/v1/subscription/history/list`,
  detail: (id) => `/api/v1/course/detail/${id}`,
  delete: (id) => `/api/v1/course/delete/${id}`,
};

const state = () => ({ loading: null, list: [], listMeta: {}, detail: {}, subsList: {} });
const getters = {};
const mutations = {
  setList(state, data) {
    state.list = data.list;
    state.listMeta = { ...data, list: undefined };
  },
  setDetail(state, data) {
    state.detail = data;
  },
  setSubsList(state, data) {
    state.subsList = data;
  },
  setLoading(state, data) {
    state.loading = data;
  },
};
const actions = {
  async request(context, { url, method = 'get', config = {} }) {
    let response = { error: false, data: {} };
    try {
      const { data } = await request(method, url, config);
      response.data = data;
    } catch (error) {
      response.error = true;
      response.data = error;
      if (error.response) {
        response.data = error.response.data;
      }
    }
    return response;
  },
  async getList(context, params = {}) {
    context.commit('setLoading', true);
    const res = await context.dispatch('request', { url: api.list, config: { params } });
    if (!res.error) {
      context.commit('setList', res.data.data);
    }
    context.commit('setLoading', false);
    return res;
  },
  async getDetail(context, id) {
    context.commit('setLoading', true);
    const res = await context.dispatch('request', { url: api.detail(id) });
    if (!res.error) {
      context.commit('setDetail', res.data.data);
    }
    context.commit('setLoading', false);
    return res;
  },
  async deleteItem(context, id) {
    context.commit('setLoading', true);
    const res = await context.dispatch('request', { url: api.delete(id), method: 'delete' });
    context.commit('setLoading', false);
    return res;
  },
  async getSubsList(context, params = {}) {
    context.commit('setLoading', true);
    const res = await context.dispatch('request', { url: api.subscriptions, config: { params } });
    if (!res.error) {
      context.commit('setSubsList', res.data.data);
    }
    context.commit('setLoading', false);
    return res;
  },
};

export default { namespaced: true, state, getters, mutations, actions };
