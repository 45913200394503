const Dashboard = () => import(/* webpackPrefetch: true */ '@/views/private/Dashboard.vue');
const Users = () => import(/* webpackPrefetch: true */ '@/views/private/users/UsersList.vue');
const UsersUpdate = () => import(/* webpackPrefetch: true */ '@/views/private/users/UsersUpdate.vue');
const UsersDetail = () => import(/* webpackPrefetch: true */ '@/views/private/users/UsersDetail.vue');
const Settings = () => import(/* webpackPrefetch: true */ '@/views/private/Settings.vue');

/* Category ROUTE */
const Category = () => import(/* webpackPrefetch: true */ '@/views/private/category/CategoryList.vue');
const CategoryCreate = () => import(/* webpackPrefetch: true */ '@/views/private/category/Create.vue');
const CategoryUpdate = () => import(/* webpackPrefetch: true */ '@/views/private/category/Update.vue');

/* PRICE PACKAGE ROUTE */
const Course = () => import(/* webpackPrefetch: true */ '@/views/private/course/Index.vue');
const CourseCreate = () => import(/* webpackPrefetch: true */ '@/components/course/Create.vue');
const CourseUpdate = () => import(/* webpackPrefetch: true */ '@/components/course/Update.vue');

/* COURSE ROUTE */
const PricePackage = () => import(/* webpackPrefetch: true */ '@/views/private/package/Index.vue');

/* TOPIC ROUTE */
const Topic = () => import(/* webpackPrefetch: true */ '@/components/course/topic/TopicList.vue');
// const Topic = () => import(/* webpackPrefetch: true */ '@/components/course/topic/ListFree.vue');
const TopicCreate = () => import(/* webpackPrefetch: true */ '@/components/course/topic/Create.vue');
const TopicUpdate = () => import(/* webpackPrefetch: true */ '@/components/course/topic/Update.vue');

/* ACTIVITY ROUTE */
const Activity = () => import(/* webpackPrefetch: true */ '@/components/course/activity/List.vue');
const ActivityCreate = () => import(/* webpackPrefetch: true */ '@/components/course/activity/Create.vue');
const ActivityUpdate = () => import(/* webpackPrefetch: true */ '@/components/course/activity/Update.vue');

/* QUESTIONS ROUTE CREATE */
const ListQuestions = () => import(/* webpackPrefetch: true */ '@/components/course/Questions/List.vue');
const QuestionInputSelected = () => import(/* webpackPrefetch: true */ '@/components/course/Questions/QuestionInputSelected.vue');

const routes = [
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    meta: {
      title: "Dashboard"
    }
  },
  {
    path: "/category",
    name: "category",
    component: Category,
    meta: {
      title: "List Category"
    }
  },
  {
    path: "/category/create",
    name: "category-create",
    component: CategoryCreate,
    meta: {
      title: "Create Category"
    }
  },
  {
    path: "/category/update/:id",
    name: "category-update",
    component: CategoryUpdate,
    meta: {
      title: "Update Category"
    }
  },
  {
    path: "/users",
    name: "User",
    component: Users,
    meta: {
      title: "User"
    }
  },
  {
    path: "/users/update/:id",
    name: "user-update",
    component: UsersUpdate,
    meta: {
      title: "User Update"
    }
  },
  {
    path: "/users/detail/:id",
    name: "user-detail",
    component: UsersDetail,
    meta: {
      title: "User Detail"
    }
  },
  {
    path: "/settings",
    name: "Setting",
    component: Settings,
    meta: {
      title: "Setting"
    }
  },

  /* COURSE ROUTE */
  {
    path: "/course",
    name: "Course",
    component: Course,
    meta: {
      title: "Course"
    }
  },
  {
    path: "/course/create",
    name: "Course Create",
    component: CourseCreate,
    meta: {
      title: "Course Create"
    }
  },
  {
    path: "/course/update/:id",
    name: "Course Update",
    component: CourseUpdate,
    meta: {
      title: "Course Update"
    }
  },

  /* PRICE PACAKGE ROUTE */
  {
    path: "/price/package",
    name: "Price Package",
    component: PricePackage,
    meta: {
      title: "Price Package"
    }
  },

  /* TOPIC ROUTE */
  {
    path: "/topic",
    name: "topic",
    component: Topic,
    meta: {
      title: "List Topic"
    }
  },
  {
    path: "/topic/create",
    name: "topic-create",
    component: TopicCreate,
    meta: {
      title: "Topic Create"
    }
  },
  {
    path: "/topic/update/:id",
    name: "topic-update",
    component: TopicUpdate,
    meta: {
      title: "Topic Update"
    }
  },

  /* ACTIVITY ROUTE */
  {
    // path: "/activity/:id_course/:id_topic",
    path: "/activity/:id_topic",
    name: "Activity",
    component: Activity,
    meta: {
      title: "Activity and Partings"
    }
  },
  {
    // path: "/activity/create/:id_course/:id_topic",
    path: "/activity/create/:id_topic",
    name: "Activity Create",
    component: ActivityCreate,
    meta: {
      title: "Activity Create"
    }
  },
  {
    // path: "/activity/update/:id_course/:id_topic/:id_activity",
    path: "/activity/update/:id_topic/:id_activity",
    name: "Activity Update",
    component: ActivityUpdate,
    meta: {
      title: "Activity Update"
    }
  },
  /* ACTIVITY ROUTE END */
  /*
  */

  /**
  * QUESTIONS ROUTE START
  */
  {
    // path: "/questions/list/:id_course/:id_topic/:id_activity",
    path: "/questions/list/:id_topic/:id_activity",
    name: "List Questions",
    component: ListQuestions,
    meta: {
      title: "List Questions"
    }
  },
  {
    // path: "/questions/create/:id_course/:id_topic/:id_activity/:question_number",
    path: "/questions/create/:id_topic/:id_activity/:question_number",
    name: "Create Questions",
    component: QuestionInputSelected,
    meta: {
      title: "Create Questions"
    }
  },
  {
    // path: "/questions/update/:id_course/:id_topic/:id_activity/:id_question/:question_number",
    path: "/questions/update/:id_topic/:id_activity/:id_question/:question_number",
    name: "Update Questions",
    component: QuestionInputSelected,
    meta: {
      title: "Update Questions"
    }
  },
  /**
  * QUESTIONS ROUTE START
  */
]

export default routes.map(route => {
  const meta = {
    all: false, 
    public: false,
    title: route.meta.title
  }
	return { ...route, meta }
})