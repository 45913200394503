import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import VeeValidate from '@/plugins/VeeValidate.js'
import Convert from "./plugins/Convert.js"
import Lodash from "./plugins/Lodash.js"
import VueExcelXlsx from "./plugins/VueExcelXlsx.js"
import VCurrencyField from "./plugins/VCurrencyField"
import VueApexCharts from "./plugins/VueApexCharts.js"
import VueCtkDateTimePicker from "./plugins/VueCtkDateTimePicker.js"

Vue.config.productionTip = false

import '@/assets/css/main.css'

import { TokenService } from "@/service/Storage.Service.js"

if (TokenService.getToken()) {
  store.commit("setToken", TokenService.getToken())
  store.commit("setUser", JSON.parse(TokenService.getUser()))
}

new Vue({
  Convert,
  Lodash,
  router,
  store,
  vuetify,
  VeeValidate,
  VueExcelXlsx,
  VueApexCharts,
  VCurrencyField,
  VueCtkDateTimePicker,
  render: h => h(App)
}).$mount('#app')
