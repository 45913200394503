const routes = [
  {
    path: '/progress',
    name: 'progress-list',
    component: () => import(/* webpackPrefetch: true */ '@/views/private/progress/progress-list-rev-v2'),
    meta: {
      title: 'Progress',
    },
  },
  {
    path: '/progress/:id',
    name: 'progress-detail',
    component: () => import(/* webpackPrefetch: true */ '@/views/private/progress/progress-detail-rev'),
    meta: {
      title: 'Progress Detail',
    },
  },
];

export default routes.map((route) => {
  const meta = {
    all: false,
    public: false,
    title: route.meta.title,
  };
  return { ...route, meta };
});
