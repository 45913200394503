/* QUESTIONS ROUTE UPDATE */
// const ListQuestions = () => import(/* webpackPrefetch: true */ '@/components/course/CreateQuestion/List.vue');
const VocabularyQuestions = () => import(/* webpackPrefetch: true */ '@/components/course/CreateQuestion/Vocabulary.vue');
const VoiceRecordQuestions = () => import(/* webpackPrefetch: true */ '@/components/course/CreateQuestion/VoiceRecord.vue');
const ListeningQuestions = () => import(/* webpackPrefetch: true */ '@/components/course/CreateQuestion/Listening.vue');
const ConstructorWordQuestions = () => import(/* webpackPrefetch: true */ '@/components/course/CreateQuestion/ConstructorWord.vue');
const ReadingQuestions = () => import(/* webpackPrefetch: true */ '@/components/course/CreateQuestion/Reading.vue');
const MatchingQuestions = () => import(/* webpackPrefetch: true */ '@/components/course/CreateQuestion/Matching.vue');
const SentenceRecordQuestions = () => import(/* webpackPrefetch: true */ '@/components/course/CreateQuestion/SentenceRecord.vue');
const FillTheBlankQuestions = () => import(/* webpackPrefetch: true */ '@/components/course/CreateQuestion/FillTheBlank.vue');

const routes = [
  // {
  //   path: "/questions/:id_course/:id_topic/:id_activity/:activity_type",
  //   name: "List Questions",
  //   component: ListQuestions,
  //   meta: {
  //     title: "List Questions"
  //   }
  // },
  {
    path: "/questions/vocabulary/update/:id_course/:id_topic/:id_activity/:id_question/:activity_type",
    name: "Vocabulary Questions Update",
    component: VocabularyQuestions,
    meta: {
      title: "Vocabulary Questions Update"
    }
  },
  {
    path: "/questions/voices/update/:id_course/:id_topic/:id_activity/:id_question/:activity_type",
    name: "Voice Record Questions Update",
    component: VoiceRecordQuestions,
    meta: {
      title: "Voice Record Questions Update"
    }
  },
  {
    path: "/questions/listening/update/:id_course/:id_topic/:id_activity/:id_question/:activity_type",
    name: "Listening Questions Update",
    component: ListeningQuestions,
    meta: {
      title: "Listening Questions Update"
    }
  },
  {
    path: "/questions/constructorword/update/:id_course/:id_topic/:id_activity/:id_question/:activity_type",
    name: "Constructor Word Questions Update",
    component: ConstructorWordQuestions,
    meta: {
      title: "Constructor Word Questions Update"
    }
  },
  {
    path: "/questions/reading/update/:id_course/:id_topic/:id_activity/:id_question/:activity_type",
    name: "Reading Questions Update",
    component: ReadingQuestions,
    meta: {
      title: "Reading Questions Update"
    }
  },
  {
    path: "/questions/matching/update/:id_course/:id_topic/:id_activity/:id_question/:activity_type",
    name: "Matching Image Questions Update",
    component: MatchingQuestions,
    meta: {
      title: "Matching Image Questions Update"
    }
  },
  {
    path: "/questions/sentencerecord/update/:id_course/:id_topic/:id_activity/:id_question/:activity_type",
    name: "Sentence Record Questions Update",
    component: SentenceRecordQuestions,
    meta: {
      title: "Sentence Record Questions Update"
    }
  },
  {
    path: "/questions/filltheblank/update/:id_course/:id_topic/:id_activity/:id_question/:activity_type",
    name: "Fill The Blank Questions Update",
    component: FillTheBlankQuestions,
    meta: {
      title: "Fill The Blank Questions Update"
    }
  },
]
  
export default routes.map(route => {
  const meta = {
    all: false, 
    public: false,
    title: route.meta.title
  }
    return { ...route, meta }
})