import allRoutes from '@/router/routes/all.js';
import publicRoutes from '@/router/routes/public.js';
import privateRoutes from '@/router/routes/private.js';
import questionsCreate from '@/router/routes/questionsCreate.js';
import questionsUpdate from '@/router/routes/questionsUpdate.js';
import ProgressRoute from './ProgressRoute';

export default allRoutes.concat(
  publicRoutes,
  privateRoutes,
  questionsCreate,
  questionsUpdate,
  ProgressRoute
);
